<template>
    <div v-loading.fullscreen.lock="loading">
        <cop-order-search-bar-component
            @search="get_cop_order_index"
        ></cop-order-search-bar-component>
        <common-table-component
            details_auth="中远订单申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_cop_order"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_cop_order_index"
        ></common-page-component>
        <cop-order-edit-component
            :id="fid"
            :dialogFormVisible="fdialogFormVisible"
            :CopOrder="fcop_order_details_data"
            :del_auth="del_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            @exitDialog="dialogExit"
            @search="get_cop_order_index"
            @frefresh="frefresh"
        ></cop-order-edit-component>
    </div>
</template>

<script>
import { cop_order_index_request,cop_order_details_request } from '@/network/cop/CopOrder.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import CopOrderSearchBarComponent from '@/components/cop/CopOrder/CopOrderSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CopOrderEditComponent from '@/components/cop/CopOrder/CopOrderEditComponent'

export default {
    name: 'CopOrderView',
    data() {
        return {
            fid: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                state: '',
                create_staff: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '订单号',
                    minWidth: '120px'
                },{
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },{
                    prop: 'cop_check',
                    label: '核验状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'success',
                                filter_data: '无异常'
                            }
                        } else if(d===1) {
                            return {
                                type: 'warning',
                                filter_data: '数量异常'
                            }
                        } else if(d===2) {
                            return {
                                type: 'danger',
                                filter_data: '价格异常'
                            }
                        } else {
                            return {
                                type: 'info',
                                filter_data: '未核验'
                            }
                        }
                    }
                },{
                    prop: 'start_city',
                    label: '出发地',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                },{
                    prop: 'end_city',
                    label: '目的地',
                    minWidth: '100px',
                    show_overflow_tooltip: true
                },{
                    prop: 'start_time',
                    label: '开始日期',
                    minWidth: '100px'
                },{
                    prop: 'end_time',
                    label: '结束日期',
                    minWidth: '100px'
                },{
                    prop: 'estimate_money',
                    label: '金额',
                    minWidth: '160px',
                    html: (row) => {
                        let str =''
                        const arr = row['estimate_money'].split('|')
                        str += `<div style="color: #00BB00;font-weight: bold;">CNY ` + thousandBitSeparator(fomatFloat(arr[0])) + `</div>`
                        str += `<div style="color: #8600FF;font-weight: bold;">USD ` + thousandBitSeparator(fomatFloat(arr[1])) + `</div>`
                        return str
                    }
                },{
                    prop: 'title',
                    label: '标题',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },{
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px'
                },{
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '100px'
                },{
                    prop: 'create_time',
                    label: '创建时间',
                    minWidth: '160px'
                }
            ],
            fcop_order_details_data: {},
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed: {},
    methods: {
        get_cop_order_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.create_staff = param.create_staff ?? this.cond.create_staff
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            cop_order_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_cop_order(id) {
            this.fid = id
            this.fdialogFormVisible = true
            cop_order_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fcop_order_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
        },
        frefresh() {
            this.details_cop_order(this.fid)
            this.get_cop_order_index()
        },
        is_auth(res) {
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交中远订单申请')
                    this.del_auth = this.$_has('删除中远订单申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回中远订单申请')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批中远订单申请')
                }
                this.sel_approval_auth = this.$_has('查看中远订单申请审批记录')
            } else if(res.state === 2) {
                this.sel_approval_auth = this.$_has('查看中远订单申请审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交中远订单申请')
                    this.del_auth = this.$_has('删除中远订单申请')
                }
                this.sel_approval_auth = this.$_has('查看中远订单申请审批记录')
            }
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_cop_order_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CopOrderSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CopOrderEditComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>